import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Screenshot = makeShortcode("Screenshot");
const Cloudinary = makeShortcode("Cloudinary");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In GraphOS Studio, click on your profile picture, then `}<em parentName="p">{`Personal Settings`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click `}<em parentName="p">{`API Keys`}</em>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click `}<em parentName="p">{`Create new key`}</em>{`.`}</p>
        <Screenshot url="https://studio.apollographql.com" mdxType="Screenshot">
          <Cloudinary src="v1734643471/odyssey/personal-api-key.png" alt="Personal API Key generated in Studio" mdxType="Cloudinary" />
        </Screenshot>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Give it a name, like "Local Dev".`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy your key—you won't be able to see it again!`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In a terminal, run:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`rover config auth
`}</code></pre>
        <p parentName="li">{`Then paste in your API key (you won't be able to see it physically in the terminal).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`To check if it worked, run:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`rover config whoami
`}</code></pre>
        <p parentName="li">{`And you should see your profile information displayed. You're all set!`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      