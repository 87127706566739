import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { FiBookOpen } from "react-icons/fi";
import AuthenticateRoverSteps from "./authenticate-rover-steps.mdx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CollapsibleSection = makeShortcode("CollapsibleSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <CollapsibleSection title="Instructions to install and authenticate Rover" icon={FiBookOpen} mdxType="CollapsibleSection">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Open up a terminal and run the install command that suits your computer's environment:`}</p>
          <p parentName="li">{`For Linux / Mac OS:`}</p>
          <pre parentName="li"><code parentName="pre" {...{
              "className": "language-shell"
            }}>{`curl -sSL https://rover.apollo.dev/nix/latest | sh
`}</code></pre>
          <p parentName="li">{`For Windows PowerShell installer`}</p>
          <pre parentName="li"><code parentName="pre" {...{
              "className": "language-shell"
            }}>{`iwr 'https://rover.apollo.dev/win/latest' | iex
`}</code></pre>
          <p parentName="li"><strong parentName="p">{`Note`}</strong>{`: You can `}<a parentName="p" {...{
              "href": "https://www.apollographql.com/docs/rover/getting-started/#installation-methods"
            }}>{`find other installation methods in the Apollo documentation`}</a>{`.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Verify that the installation completed successfully by running `}<inlineCode parentName="p">{`rover`}</inlineCode>{` anywhere in the terminal.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`If it outputs a list of options and subcommands for using Rover, great! The CLI is installed and ready to go. Next, let's authenticate it with a personal API key.`}</p>
        </li>
      </ol>
      <AuthenticateRoverSteps mdxType="AuthenticateRoverSteps" />
    </CollapsibleSection>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      